<template>
  <div class="number_area">
    <ul class="number_list">
      　<li>証券番号 {{ contract.policyNumber }}</li>
      　<li>案件番号 {{ contract.number }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ContractNumber',
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
};
</script>

<style>
.number_area {
  text-align: left;
}

.number_list {
  list-style: none;
  width: -moz-fit-content; /* Firefox */
  width: fit-content; /* Firefox以外 */
  margin-left: auto; /* 画面によって左寄せ等ある場合はpropsで指定するように変更する */
}
</style>
