<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container style="width: 90%; max-height: 90vh;" class="mx-auto">
        <v-row>
          <v-card-title class="font-weight-bold pt-2 pl-1 pa-0">
            {{ title }}
          </v-card-title>
          <div class="pt-2 pl-5 pr-5">
            <v-row>
              <p>
                {{ $t('description.finalReviewContract.explanation1') }}
              </p>
            </v-row>
            <v-row>
              <p>
                {{ $t('description.finalReviewContract.explanation2') }}
              </p>
            </v-row>
          </div>

          <v-row>
            <v-col cols="12">
              <ContractNumber
                  :contract="contract"
              />
            </v-col>
          </v-row>

          <v-container class="pt-0" ref="finalReviewContractPdf">
            <v-row>
              <table
                class="ma-0"
                style="width: 100%; border-spacing: 5px; font-size: 14px; table-layout: fixed;"
              >
                <StepFirstContent
                  :contract="contract"
                />
                <StepSecondContent
                  :contract="contract"
                />
                <StepThirdContent
                  :contract="contract"
                  :planChoices="planChoices"
                  :finalReviewFlag="finalReviewFlag"
                />
                <StepFourthContent
                  :contract="contract"
                />
              </table>
            </v-row>
          </v-container>
        </v-row>
        <div class="pt-2 pl-2 pr-2">
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation3') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation4') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation5-1') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation5-2') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation6') }}</p>
          </v-row>
          <v-row>
            <p>{{ $t('description.finalReviewContract.explanation7') }}</p>
          </v-row>
          <v-row v-if="!earthquakeIncluded">
            <p>{{ earthquakeInsuranceExplanation }}</p>
            <div class="text-center float-left mt-4 mb-8">
              <input
                type="checkbox"
                id="checkboxNotAttached"
                v-model="isCheckedNotAttached"
                :disabled="isCheckBoxDisabledNotAttached"
                @change="onClickCheckBox"
              />
              <label for="checkboxNotAttached">{{ checkboxLabel }}</label>
            </div>
          </v-row>
          <v-row>
            <strong>
              {{ $t('description.finalReviewContract.explanation9') }}
            </strong>
          </v-row>
          <v-row>
            <div class="text-center float-left mt-8">
              <input
                type="checkbox"
                id="checkboxConfirmed"
                v-model="isCheckedConfirmed"
                :disabled="isCheckBoxDisabledConfirmed"
                @change="onClickCheckBox"
              />
              <label for="checkboxConfirmed">{{ $t('checkbox.confirmed') }}</label>
            </div>
          </v-row>
        </div>
        <v-row v-if="isError" class="pl-2 pr-2 pt-4">
          <p>
            <font color="error">{{ errorText }}</font>
          </p>
        </v-row>
        <v-row class="my-8">
          <v-col
            style="padding-left:0px; padding-right:10px; text-align:center"
          >
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              @click="onBackClicked"
              width="33vw"
              height="50px"
              max-width="170px"
              color="back"
              style="font-size:20px"
              rounded
              :dark="!isBackButtonDisable"
              :disabled="isBackButtonDisable"
              >{{ $t('button.back') }}</v-btn
            >
          </v-col>
          <v-col
            style="padding-left:10px; padding-right:0px; text-align:center"
          >
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="33vw"
              height="50px"
              max-width="170px"
              color="next"
              style="font-size:14px"
              rounded
              :dark="isApplyButtonEnabled"
              :disabled="!isApplyButtonEnabled"
              @click="openDialog"
              >{{ $t('button.apply') }}
            </v-btn>
          </v-col>
        </v-row>
        <Dialog
          :maxWidth="320"
          :showDialog="showDialog"
          :title="null"
          :text="dialogText"
          :subText="$t('description.finalReviewContract.confirm2')"
          :negativeButtonTitle="$t('button.back')"
          :positiveButtonTitle="$t('button.yes')"
          :onClickNegativeButton="closeDialog"
          :onClickPositiveButton="apply"
        />
      </v-container>
    </v-layout>
    <ScrollToTopButton />
  </v-container>
</template>

<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Dialog from '@/components/organisms/contractor/Dialog.vue';
import ContractNumber from '@/components/molecules/contractor/ContractNumber.vue';
import { mapActions, mapGetters } from 'vuex';
import { getBaseInfo, submitContract } from '@/apis/contractor/contracts';
import ScrollToTopButton from '@/components/organisms/common/ScrollToTopButton.vue';
import StepFirstContent from '@/pages/contractor/reviewContractTemplates/StepFirstContent.vue';
import StepSecondContent from '@/pages/contractor/reviewContractTemplates/StepSecondContent.vue';
import StepThirdContent from '@/pages/contractor/reviewContractTemplates/StepThirdContent.vue';
import StepFourthContent from '@/pages/contractor/reviewContractTemplates/StepFourthContent.vue';

import { completedStepNumber } from '@/lib/const';
import { splitMessageLine } from '@/lib/commonUtil';
import { getContract } from '@/apis/contractor/contracts';

export default {
  components: {
    Dialog,
    ContractNumber,
    ScrollToTopButton,
    StepFirstContent,
    StepSecondContent,
    StepThirdContent,
    StepFourthContent,
  },
  props: {
    docs: Array,
    onBackClicked: Function,
  },
  data: () => ({
    showDialog: false,
    dialogText: '',
    title: '',
    contract: {},
    isApplyButtonEnabled: false,
    isBackButtonDisable: false,
    isCheckBoxDisabledNotAttached: false,
    isCheckBoxDisabledConfirmed: false,
    isCheckedNotAttached: false,
    isCheckedConfirmed: false,
    isError: false,
    errorText: null,
    finalReviewFlag: true,
    planChoices: [],
    pdfData: [],
  }),
  methods: {
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async apply() {
      this.closeDialog();
      await this.generatePDF();
      if (!this.earthquakeIncluded) {
        this.isCheckBoxDisabledNotAttached = false;
      }
      this.isApplyButtonEnabled = false;
      this.isBackButtonDisable = true;
      const file = this.docs
        ? this.docs.map(v => {
            return v.fileData;
          })
        : null;

      const response = await submitContract(
        this.selectedPlan.id ? this.selectedPlan.id : '',
        file,
        this.pdfData
      );
      if (response instanceof Error) {
        this.isApplyButtonEnabled = true;
        this.isCheckBoxDisabledNotAttached = false;
        this.isCheckBoxDisabledConfirmed = false;
        this.isBackButtonDisable = false;
        this.isError = true;
        return;
      }
      this.setPendingStep(completedStepNumber);
      this.setIsProcedureSkipped(false);
      this.$router.push({
        path: '/procedure_complete',
      });
    },
    onClickCheckBox() {
      if (this.earthquakeIncluded) {
        this.$set(this, 'isApplyButtonEnabled', this.isCheckedConfirmed);
      } else {
        this.$set(
          this,
          'isApplyButtonEnabled',
          this.isCheckedNotAttached && this.isCheckedConfirmed
        );
      }
    },

    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },

    // 契約内容PDF出力処理
    async generatePDF() {
      const element = this.$refs.finalReviewContractPdf;
      const pdf = new jsPDF('p', 'pt', [
        element.clientHeight,
        element.clientWidth,
      ]);

      pdf.addImage(
        await html2canvas(element, {
          scale: 1.2, // scaleの値が大きいとcanvas変換後のサイズが大きすぎて、iosの場合にエラーが発生する。
        }),
        'jpeg',
        0,
        0,
        element.clientWidth,
        element.clientHeight
      );

      this.pdfData.push(pdf.output('blob'));
      pdf.close();
    },
    ...mapActions('contract', ['setPendingStep', 'setIsProcedureSkipped']),
  },

  computed: {
    ...mapGetters('contract', ['selectedPlan']),

    // 保険の対象(建物)の有無
    isBuildingInsuranced() {
      return /[1-9１-９]/.test(this.selectedPlan.planPayout.buildingBasicPrice);
    },

    // 保険の対象(家財)の有無
    isHouseItemInsuranced() {
      return /[1-9１-９]/.test(this.selectedPlan.planPayout.houseItemBasicPrice);
    },

    // 建物(地震保険付帯)の有無
    isBuildingEarthquakeIncluded() {
      return /[1-9１-９]/.test(this.selectedPlan.planPayout.buildingEarthquakePrice);
    },

    // 家財(地震保険付帯)の有無
    isHouseItemEarthquakeIncluded() {
      return /[1-9１-９]/.test(this.selectedPlan.planPayout.houseItemEarthquakePrice);
    },

    // 地震保険に対する表示文言を取得する。
    earthquakeInsuranceExplanation() {
      if (
          (
            this.isBuildingInsuranced ||
            this.isHouseItemInsuranced
          ) &&
          !this.isBuildingEarthquakeIncluded &&
          !this.isHouseItemEarthquakeIncluded
      ) {
        return this.$t('description.finalReviewContract.explanation8-1');
      }


      if (
          this.isBuildingInsuranced &&
          this.isHouseItemInsuranced &&
          !this.isBuildingEarthquakeIncluded &&
          this.isHouseItemEarthquakeIncluded
      ) {
        return this.$t('description.finalReviewContract.explanation8-2');
      }

      if (
          this.isBuildingInsuranced &&
          this.isHouseItemInsuranced &&
          this.isBuildingEarthquakeIncluded &&
          !this.isHouseItemEarthquakeIncluded
      ) {
        return this.$t('description.finalReviewContract.explanation8-3');
      }

      throw new Error('invalid buildingBasicPrice or houseItemBasicPrice or buildingEarthquakePrice or houseItemEarthquakePrice')
    },

    // チェックボックスの表示文言を取得する。
    checkboxLabel() {
      if (
          (
            this.isBuildingInsuranced ||
            this.isHouseItemInsuranced
          ) &&
          !this.isBuildingEarthquakeIncluded &&
          !this.isHouseItemEarthquakeIncluded
      ) {
        return this.$t('checkbox.notAttached');
      }

      if (
          this.isBuildingInsuranced &&
          this.isHouseItemInsuranced &&
          !this.isBuildingEarthquakeIncluded &&
          this.isHouseItemEarthquakeIncluded
      ) {
        return this.$t('checkbox.notAttachedBuilding');
      }

      if (
          this.isBuildingInsuranced &&
          this.isHouseItemInsuranced &&
          this.isBuildingEarthquakeIncluded &&
          !this.isHouseItemEarthquakeIncluded
      ) {
        return this.$t('checkbox.notAttachedHouseItem');
      }

      throw new Error('invalid buildingBasicPrice or houseItemBasicPrice or buildingEarthquakePrice or houseItemEarthquakePrice')
    },

    // 地震保険付帯されているかどうか
    earthquakeIncluded() {
      return (this.isBuildingInsuranced && !this.isHouseItemInsuranced && this.isBuildingEarthquakeIncluded && !this.isHouseItemEarthquakeIncluded) || // 建物のみ/建物のみ地震あり
        (!this.isBuildingInsuranced && this.isHouseItemInsuranced && !this.isBuildingEarthquakeIncluded && this.isHouseItemEarthquakeIncluded) || // 家財のみ/家財のみ地震あり
        (this.isBuildingInsuranced && this.isHouseItemInsuranced && this.isBuildingEarthquakeIncluded && this.isHouseItemEarthquakeIncluded) // 建物・家財/建物地震あり・家財地震あり
    },
  },
  async mounted() {
    this.contract = await getContract().catch(() => {});

    this.planChoices.push(this.selectedPlan);

    this.dialogText = this.$t('description.finalReviewContract.confirm1');

    const buttonName = this.$t('button.apply');

    this.errorText = `${this.$t(
      'error.failureToApplyHeader'
    )}「${buttonName}」ボタン${this.$t('error.failureToApplyFooter')}`;

    this.title = this.$t('title.contractor.finalReviewContract');

    const response = await getBaseInfo();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
  },
};
</script>
<style>
.v_base_table_with_border {
  border: solid 1px;
}
.base_td_category_text {
  width: 33%;
  background: #d5ebfe;
}
.v-application p {
  word-break: break-word;
}
.v-data-table {
  overflow: hidden;
}
</style>
