var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto",
              staticStyle: { width: "90%", "max-height": "90vh" },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-bold pt-2 pl-1 pa-0" },
                    [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "pt-2 pl-5 pr-5" },
                    [
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "description.finalReviewContract.explanation1"
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _c("v-row", [
                        _c("p", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "description.finalReviewContract.explanation2"
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("ContractNumber", {
                            attrs: { contract: _vm.contract },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-container",
                    { ref: "finalReviewContractPdf", staticClass: "pt-0" },
                    [
                      _c("v-row", [
                        _c(
                          "table",
                          {
                            staticClass: "ma-0",
                            staticStyle: {
                              width: "100%",
                              "border-spacing": "5px",
                              "font-size": "14px",
                              "table-layout": "fixed",
                            },
                          },
                          [
                            _c("StepFirstContent", {
                              attrs: { contract: _vm.contract },
                            }),
                            _c("StepSecondContent", {
                              attrs: { contract: _vm.contract },
                            }),
                            _c("StepThirdContent", {
                              attrs: {
                                contract: _vm.contract,
                                planChoices: _vm.planChoices,
                                finalReviewFlag: _vm.finalReviewFlag,
                              },
                            }),
                            _c("StepFourthContent", {
                              attrs: { contract: _vm.contract },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pt-2 pl-2 pr-2" },
                [
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("description.finalReviewContract.explanation3")
                        )
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("description.finalReviewContract.explanation4")
                        )
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "description.finalReviewContract.explanation5-1"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "description.finalReviewContract.explanation5-2"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("description.finalReviewContract.explanation6")
                        )
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$t("description.finalReviewContract.explanation7")
                        )
                      ),
                    ]),
                  ]),
                  !_vm.earthquakeIncluded
                    ? _c("v-row", [
                        _c("p", [
                          _vm._v(_vm._s(_vm.earthquakeInsuranceExplanation)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "text-center float-left mt-4 mb-8" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isCheckedNotAttached,
                                  expression: "isCheckedNotAttached",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                id: "checkboxNotAttached",
                                disabled: _vm.isCheckBoxDisabledNotAttached,
                              },
                              domProps: {
                                checked: Array.isArray(_vm.isCheckedNotAttached)
                                  ? _vm._i(_vm.isCheckedNotAttached, null) > -1
                                  : _vm.isCheckedNotAttached,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.isCheckedNotAttached,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.isCheckedNotAttached =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.isCheckedNotAttached = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.isCheckedNotAttached = $$c
                                    }
                                  },
                                  _vm.onClickCheckBox,
                                ],
                              },
                            }),
                            _c(
                              "label",
                              { attrs: { for: "checkboxNotAttached" } },
                              [_vm._v(_vm._s(_vm.checkboxLabel))]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("v-row", [
                    _c("strong", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "description.finalReviewContract.explanation9"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _c("v-row", [
                    _c("div", { staticClass: "text-center float-left mt-8" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.isCheckedConfirmed,
                            expression: "isCheckedConfirmed",
                          },
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "checkboxConfirmed",
                          disabled: _vm.isCheckBoxDisabledConfirmed,
                        },
                        domProps: {
                          checked: Array.isArray(_vm.isCheckedConfirmed)
                            ? _vm._i(_vm.isCheckedConfirmed, null) > -1
                            : _vm.isCheckedConfirmed,
                        },
                        on: {
                          change: [
                            function ($event) {
                              var $$a = _vm.isCheckedConfirmed,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.isCheckedConfirmed = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.isCheckedConfirmed = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.isCheckedConfirmed = $$c
                              }
                            },
                            _vm.onClickCheckBox,
                          ],
                        },
                      }),
                      _c("label", { attrs: { for: "checkboxConfirmed" } }, [
                        _vm._v(_vm._s(_vm.$t("checkbox.confirmed"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _vm.isError
                ? _c("v-row", { staticClass: "pl-2 pr-2 pt-4" }, [
                    _c(
                      "p",
                      [
                        _c("font", { attrs: { color: "error" } }, [
                          _vm._v(_vm._s(_vm.errorText)),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "v-row",
                { staticClass: "my-8" },
                [
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-left": "0px",
                        "padding-right": "10px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            width: "33vw",
                            height: "50px",
                            "max-width": "170px",
                            color: "back",
                            rounded: "",
                            dark: !_vm.isBackButtonDisable,
                            disabled: _vm.isBackButtonDisable,
                          },
                          on: { click: _vm.onBackClicked },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.back")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticStyle: {
                        "padding-left": "10px",
                        "padding-right": "0px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "14px" },
                          attrs: {
                            align: "center",
                            width: "33vw",
                            height: "50px",
                            "max-width": "170px",
                            color: "next",
                            rounded: "",
                            dark: _vm.isApplyButtonEnabled,
                            disabled: !_vm.isApplyButtonEnabled,
                          },
                          on: { click: _vm.openDialog },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("button.apply")) + "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Dialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showDialog,
                  title: null,
                  text: _vm.dialogText,
                  subText: _vm.$t("description.finalReviewContract.confirm2"),
                  negativeButtonTitle: _vm.$t("button.back"),
                  positiveButtonTitle: _vm.$t("button.yes"),
                  onClickNegativeButton: _vm.closeDialog,
                  onClickPositiveButton: _vm.apply,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ScrollToTopButton"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }